@import "assets/scss/variable";

$bullet-size: 16px;

.custom-control-switch {
  font-size: $font-size-base;
  cursor: pointer;
  padding: 4px 0;
  display: flex;
  align-items: center;

  &__input {
    position: relative;
    display: inline-block;
    height: 12px;
    width: 26px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 9999px;
    background-color: $neutral-20;
    transition: all .3s ease;

    input {
      appearance: none;

      &:checked {
        background-color: $primary-color;
      }

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: -2px;
        display: block;
        height: $bullet-size;
        width: $bullet-size;
        cursor: pointer;
        border: 1px solid $neutral-20;
        border-radius: 9999px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 1px 3px 0 rgba($neutral, 0.2),
        0 2px 2px 0 rgba($neutral, 0.12),
        0 0 2px 0 rgba($neutral, 0.14);
        transition: all .3s ease;
      }

      &:hover::before {
        box-shadow: 0 0 0 8px rgba(0, 0, 0, .05)
      }

      &:checked:hover::before {
        box-shadow: 0 0 0 8px rgba($primary-color, .15)
      }

      &:checked:before {
        left: -6px;
        transform: translateX(100%);
        border-color: $primary-color;
        background: $primary-color;
      }
    }
  }
  &__title {
    padding-left: 8px
  }
}