
.content {
  .nav-tabs {
    min-height: unset;
    z-index: 100;
    position: relative;
    top: unset;

    .btn {
      opacity: 1;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &.disabled.active{
        background: $white-color;
        border-color: $medium-gray $medium-gray $white-color;
        color: $dark-gray
      }
    }
  }
}

.tab-content .card {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}