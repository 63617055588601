// TODO: Organize colors
$primary-color: #4e938e;
$primary-color-40: #b8d4d2;
$primary-color-16: #e3eeed;
$primary-color-8: #f1f6f6;
$primary-color-6: #f4f9f8;

$primary-dark-color-50: #3a5f5f;
$primary-dark-color-20: #467e7b;

$neutral: #262b2f; // Charleston-grey
$neutral-75: #5c6063;
$neutral-65: #727578;
$neutral-50: #929597;
$neutral-20: #d4d5d5;
$neutral-12: #e5e6e6;
$neutral-8: #f1f6f6;
$neutral-4: #f6f7f7;

$red: #d0021b;
$red-10: #fae6e8;
$red-4: #fdeae9;

$warning-10: #FDF3E0;

$padding-x: 24px;
$padding-y: 20px;

$gap: 6px;

$font-size-base:               14px !default;
$font-size-small:              12px !default;
$font-size-medium:             16px !default;
$font-size-large:              18px !default;
$font-size-large-navbar:       20px !default;

