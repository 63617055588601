@import "lbd/variables";
@import "lbd/mixins";

@import "lbd/typography";

// Core CSS
@import "lbd/misc";
@import "lbd/sidebar-and-main-panel";
@import "lbd/buttons";
@import "lbd/inputs";

@import "lbd/alerts";
@import "lbd/tables";

@import "lbd/checkbox-radio-switch";
@import "lbd/navbars";
@import "lbd/footers";

@import "lbd/tabs";

// Fancy Stuff
@import "lbd/plugins/animate";
@import "lbd/dropdown";
@import "lbd/cards";
@import "lbd/chartist";
@import "lbd/responsive";
@import "lbd/bootstrap-switch";

// Nucleo Icons
@import "lbd/partial-nucleo-icons";

// React Differences
@import "lbdr/react-differences";

// New UI
@import "new-ui";
@import "variable";

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23CHANGETHISTOYOURHEXCODE' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23CHANGETHISTOYOURHEXCODE' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.modal.show .modal-dialog {
    transform: translate(0, 0);
}

.custom-control-input:focus~.custom-control-label::before {
    border-color: #4e938e !important;
    box-shadow:
        0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #4e938e !important;
    background-color: #4e938e !important;
}

.custom-control-input:active~.custom-control-label::before {
    background-color: #4e938e !important;
    border-color: #4e938e !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #4e938e !important;
}

.custom-switch.custom-switch-md .custom-control-label {
    padding-left: 1.4rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(calc(1.5rem - 0.25rem));
}

.crop-list-container {
  display: inline-block;
  position: relative;
  min-Width: 80px;
  margin: 7px;
  text-align: center;
  cursor: pointer;

  > div {
    display: inline-block;
    min-Width: 60px;
    text-align: center;

    img {
      height: 150px;
    }
  }

  .crop-list-buttons {
    height: 42px;
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 0;
    right: 0;
    background: #3d3d3d;
    opacity: 0.7;
    color: #fff;
    cursor: pointer;
    text-align: center;
    display: none;

    span {
      flex: 0 0 50%;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      opacity: 1;

      span:hover {
        background: #4e938e;
      }

    }
  }

  &:hover {
    .crop-list-buttons {
      display: flex;
    }
  }
}

.custom-underline {
  text-decoration: underline !important;
}

.btn-danger:not(.btn-fill) {
  &.disabled,
  &:disabled,
  &[disabled] {
    color: $danger-color;
  }
}
.btn-secondary:not(.btn-fill) {
  &.disabled,
  &:disabled,
  &[disabled] {
    color: $default-color;
  }
}

.input-group {
  &--border {
      border: 1px solid $light-gray;
      border-radius: 5px;
  }
}

.alert .accordion {
  background: inherit;
}

.accordionParent {
  &.accordionError {
    .accordion:first-child {
      .card:first-child {
        border: 2px solid $danger-color;
        z-index: 10;
      }
    }
  }

  .accordion:not(:last-child) .card {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .accordion:not(:first-child) .card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .accordion > .card {
    margin-bottom: 0;

    .card-header {
      padding: 0;

      button {
        padding: 15px;
        border: none;
        background: transparent;
        width: 100%;
        text-align: left;
        color: $dark-gray;
        position: relative;

        svg {
          position: absolute;
          bottom: 20px;
          right: 15px;
        }

        &:hover {
          color: $default-color;
        }
      }
    }

    .topMinus {
      pointer-events: none;
      position: absolute;
      top: -35px;
      right: 15px;
      color: $dark-gray;
      background: $white-bg;
    }

    .card-header:hover + .show {
      .topMinus {
        color: $default-color;
      }
    }

    .collapse {
      position: relative;
    }

    .card-body {
      padding-top: 0;
      position: relative;

      .mainContainer {
        max-height: 50vh;
        overflow: auto;
        margin: 0 -15px;
        padding: 0 8px;
      }

      .topButton {
        margin-left: -8px;
      }

      .btnButton {
        padding: 5px 0 0;
        margin-left: -8px;
      }
    }
  }
}

.error-text {
  color: $danger-color;
}

.selectFixIndex {
  z-index: 3;
}